<template>
  <div class="announcementDetail">
    <div style="height: 90px"></div>
    <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/PQ?id=1' }">商标查询</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/PQ?id=4' }">商标公告</el-breadcrumb-item>
      <el-breadcrumb-item>公告详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="announcementDetail_title">公告详情</div>
    <!--    <div>{{$route.query.searchText}}</div>-->
    <div class="announcement_input">
      <div class="issue">{{$route.query.searchText}}期</div>
      <el-input placeholder="请输入申请号精确定位" class="input" v-model="text" type="text"/>
      <div @click="detail()" class="query-btn"></div>
    </div>

    <div class="choice">
      <i @click="left" class="left">
        <img v-if="!isStartPage" src="../../assets/images/query/leftArrow1.png" alt="">
        <img v-else src="../../assets/images/query/leftArrow2.png" alt="">
      </i>
      <ul>
        <li style="position: relative" v-for="(item,index) in imgArr" :key="index">
          <div   :class="[{'active': index == indexs}]">
            <div
              @click="showPdf(item,index)"
              style="position: absolute; top: 0; left: 0; width: 100%;background: transparent;"
            ></div>
<!--            <img @click="showPdf(item,index)" style="width: 100%;height: 100%;"  alt="">-->
            <iframe  :src="'https://gazette.lanternfish.cn/notice/' + item.trialImageUrl" width="100%" height="100%"></iframe>

          </div>
          <span>{{item.pageNum}}</span>
        </li>
      </ul>
      <i @click="right" class="right">
        <img v-if="!isEndPage" src="../../assets/images/query/rightArrow.png" alt="">
        <img v-else src="../../assets/images/query/rightArrow2.png" alt="">
      </i>
    </div>

    <div class="concent">
      <div class="left">
        <ul>
          <li @click="tableSwitch(index,item)" :class="[{active: index == active }]" v-for="(item,index) in mulu"
              :key="item.id">
            {{item.trialType}}
          </li>
        </ul>
      </div>
      <div class="right">
<!--        <img class="download" style="float: right" @click="pdfs" src="../../assets/images/query/load.png" alt="">-->
        <div id="pdfs">
<!--          <img v-if="pdfSrc" id="pds" style="width: 100%;height: 100%" :src="'https://gazette.lanternfish.cn/notice/' + pdfSrc" alt="">-->
          <iframe v-if="pdfSrc" :src="'https://gazette.lanternfish.cn/notice/' + pdfSrc" width="100%" height="100%"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "announcementDetail",
    data() {
      return {
        trialType: '',
        indexs: 0,
        //当前第一页
        pageIndex: 1,
        //开始页
        startPage: 1,
        endPage: 7,
        active: 0,
        //获取当前pdf
        pdfSrc: '',
        text: '',
        mulu: [],
        imgArr: [],
        trialNum: ''
      }
    },
    async mounted() {
      await this.getNoticOverView()
      if(this.$route.query.value){
        //获取公告概况信息
        this.text = this.$route.query.value
        await this.detail()
      }
    },
    computed: {
      //是否可以点击左键
      isStartPage() {
        if (this.pageIndex <= this.startPage) {
          return false;
        } else {
          return true;
        }
      },
      //是否可以点击右键
      isEndPage() {
        if (this.pageIndex >= this.endPage) {
          return false;
        } else {
          return true;
        }
      }
    },
    methods: {
      showPdf(item, index) {
        console.log(item, index,"item, indexitem, index")
        this.indexs = index
        this.pdfSrc = item.trialImageUrl
      },
      tableSwitch(val, item) {
        this.active = val
        this.indexs = 0
        this.trialNum = item.trialNum
        this.pageIndex = item.beginPage
        this.startPage = item.beginPage,
          this.endPage = item.endPage,
          this.trialNum = item.trialNum,
          this.trialType = item.trialType
          this.pdf()
      },
      //想左点击
      left() {
        if (!this.isStartPage) {
          return;
        }
        this.pageIndex -= 6
        this.pdf()
      },
      //想右点击
      right() {
        if (!this.isEndPage) {
          return;
        }
        this.pageIndex += 6
        this.pdf()
      },
      //PDF导出功能
      pdfs() {
        window.open(this.pdfSrc)
      },
      //获取公告概况信息
      async getNoticOverView() {
        await this.$axios.post('/common/query/getNoticOverView', {
          trialNum: this.$route.query.searchText
        }).then(({data}) => {
          //获取这期总目录
          this.mulu = data
          //目录定位
          this.pageIndex = data[0].beginPage
          //子目录起始页
          this.startPage = data[0].beginPage,
            //子目录结束页
            this.endPage = data[0].endPage,
            //期号
            this.trialNum = data[0].trialNum,
            this.trialType = data[0].trialType
            this.pdf()
        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish()
            console.log(error);
          })
      },
      async detail() {
        if (!this.text.trim()) {
          this.$message.error('请输入申请号');
          return;
        }
        // await this.getNoticOverView()
        await this.$axios.post('/common/query/getNoticeInfo', {
          trialNum: this.$route.query.searchText,
          appno: this.text.toString()
        }).then(({data}) => {
          if (data.length > 0) {
            this.pageIndex = data[0].pageNum
            this.indexs = 0
            // this.startPage = data[0].pageNum
            // this.endPage = data[0].endPage
            // this.trialNum = data[0].trialNum
            this.active = this.mulu.map(item => item.trialType).indexOf(data[0].trialType)
            this.trialType = data[0].trialType
            this.pdf()
          } else {
            this.$message.error('暂无结果')
          }
        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish()
            this.$message.error('查询失败');
            console.log(error);
          })
      },
      //获取子目录数据
      pdf() {
        this.$axios.post('/common/query/getNoticePages', {
          trialType: this.trialType,
          "trialNum": this.trialNum.toString(),
          "beginPage": this.pageIndex,
          "endPage": this.pageIndex + 6
        }).then(({data}) => {
          this.imgArr = data
          this.pdfSrc = data[0].trialImageUrl
          return;
        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish()
            console.log(error);
          })
      }
    }
  }
</script>

<style lang="less" scoped>
  .announcementDetail {
    width: 1300px;
    height: 1460px;
    background: #E2E8F6;
    box-shadow: 0px 0px 7px 1px rgba(14, 36, 64, 0.12);
    border-radius: 8px;
    margin: 0 auto;
    margin-bottom: 50px;

    .breadcrumb {
      width: 1085px;
      margin: 40px auto 45px;
      font-size: 15px;
      position: relative;
      padding-left: 15px;

      &:before {
        display: block;
        position: absolute;
        left: 0;
        content: '';
        height: 15px;
        width: 5px;
        background: #1A3DBF;
      }
    }

    .announcementDetail_title {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 0px;
      /*margin: 0 auto;*/
      text-align: center;
      margin: 26px 0 20px 0;
    }

    .announcement_input {
      width: 910px;
      height: 42px;
      display: flex;
      background: #FFFFFF;
      border: 1px solid #2E54D9;
      border-radius: 15px;
      margin: 0 auto;
      .issue {
        width: 78px;
        height: 42px;
        background: #1A3DBF;
        border-radius: 12px 0px 0px 12px;
        font-size: 14px;
        font-weight: 500;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .input {
        /deep/ .el-input__inner {
          height: 42px;
          border: 0px;
          outline: none;
        }
      }

      .query-btn {
        cursor: pointer;
        width: 68px;
        height: 44px;
        background: #1A3DBF;
        border-radius: 0px 15px 15px 0px;
        background: @blue url("@{imgUrl}common/search_icon.png") no-repeat center;
        margin-top: -1px;
        margin-right: -1px;
        background-size: 32%;

        &:hover {
          background: #1A3DBF url("@{imgUrl}common/search_icon.png") no-repeat center;
        }
      }
    }

    .choice {
      display: flex;
      align-items: center;
      padding: 0 14px;
      margin-top: 43px;
      box-sizing: border-box;
      justify-content: space-between;

      .left {
        cursor: pointer;
        position: relative;
        top: -30px;
      }

      .right {
        cursor: pointer;
        position: relative;
        top: -30px;
      }

      ul {
        display: flex;
        height: 250px;

        li {
          height: 200px;
          text-align: center;
          margin: 10px;
          cursor: pointer;

          div {
            width: 134px;
            height: 166px;
            background: #FFFFFF;
            overflow: hidden;
            box-shadow: 0px 0px 5px 0px rgba(14, 36, 64, 0.12);
            border-radius: 8px;

            &:hover {
              width: 148px;
              height: 188px;
            }

            &.active {
              width: 148px;
              height: 188px;
            }
          }

          span {
            display: block;
            margin-top: 11px;
            font-size: 14px;
            font-weight: 500;
            color: #555555;
          }
        }
      }
    }

    .concent {
      display: flex;
      border-top: 2px solid #F1F3FA;

      .left {
        padding: 32px 34px;
        box-sizing: border-box;
        width: 400px;
        height: 987px;
        border-radius: 0 0 0 8px;
        border-right: 2px solid #F1F3FA;
        background: #D3DBF8;

        ul {
          li {
            cursor: pointer;
            margin-bottom: 16px;
            position: relative;
            padding-left: 11px;
            font-weight: 500;
            font-size: 14px;
            color: #333333;

            &:before {
              display: block;
              content: '';
              left: 0;
              top: 3px;
              position: absolute;
              width: 4px;
              height: 14px;
              background: #1A3DBF;
            }

            &.active {
              font-weight: 500 !important;
              color: #1A3DBF !important;
            }
          }
        }
      }

      .right {
        position: relative;
        background: #E2E8F6;
        width: 100%;
        text-align: center;

        #pdfs {
          margin: 30px 0 0 25px;
          width: 834px;
          height: 925px;
          background: #FFFFFF;
          border-radius: 8px;
        }

        .download {
          position: absolute;
          cursor: pointer;
          top: 29px;
          left: 775px;
        }
      }
    }
  }
</style>
